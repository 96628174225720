@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

@import '../../css/variables';

.component-work {
    padding-top: 50px;
    position: relative;
    z-index: 1;

    .carousel-inner {
        overflow: visible;
    }
    .carousel-content {
        min-height: 128vw;
        padding-top: 10px;
        padding-bottom: 10px;

        img {
            max-width: 100%;
        }

        .col-12 {
            position: relative;
            &:last-child {
                text-align: center;
            }
        }
    }
    .work-content {
        h3 {
            font-size: 1.75rem;
        }
        .work-description {
            small {
                display: block;
                font-size: 0.6rem;
                margin-bottom: 1.3em;
            }
            .work-title {
                margin-bottom: 0.25em;
                font-weight: 400;
                font-size: 1.17rem;
            }
            .work-subtitle {
                font-weight: 300;
                font-size: 0.75em;
            }
            p {
                font-size: 0.75em;
                margin-top: 1.25em;
                margin-bottom: 0;
            }
        }
    }
    .carousel-control {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 34px;
            height: 34px;

            margin: 0 5px;
            padding: 0;

            background-color: transparent;
            transition: background-color .3s ease-in;

            border-radius: 50%;

            img {
                height: 16px;
            }
            &:hover {
                background-color: rgba(245, 245, 245, 0.3);
            }
        }
        .carousel-indicators {
            position: relative;
            li {
                width: 20px;
                margin: 0;
                position: relative;
                border-radius: 0;
                border-top: none;
                border-bottom: none;
                text-indent: 0;
                opacity: 1;
                background-color: transparent;
                color: $main-color;
                line-height: 1;
                font-size: 0.66rem;
                text-align: center;
                box-sizing: border-box;
                padding-top: calc(60px - 0.66rem);

                &::before,
                &::after {
                    content: '';
                    background-color: #fff;

                    display: block;
                    width: 1px;
                    position: absolute;
                    bottom: calc(0.66rem + 6px);
                }

                &::before {
                    height: 9px;
                    left: 50%;
                    transform: translateX(-50%);
                    transition: height .3s ease-in, background-color .3s ease-in;
                }
                &::after {
                    height: 3px;
                    right: 0;
                }

                &:last-child::after {
                    display: none;
                }

                &.active::before {
                    height: 14px;
                    background-color: $main-color;
                }

            }
        }
    }
    .carousel.carousel-fade .carousel-item {
        display: block;
        opacity: 0;
        transition: opacity ease-out .7s;
    }
    .carousel.carousel-fade .carousel-item.active {
        opacity: 1 !important;
    }
}
@include media-breakpoint-up(sm) {
    .component-work {
        .carousel-content {
            min-height: 587px;
        }
    }
}
@include media-breakpoint-up(md) {
    .component-work {
        .carousel-content {
            min-height: 753px;
        }
        .work-content {
            h3 {
                font-size: 2.33rem;
            }
            .work-description {
                .work-subtitle,
                p {
                    font-size: 0.9rem;
                }
            }
        }
        .carousel-control {
            a {
                width: 48px;
                height: 48px;
                img {
                    height: 24px;
                }
            }
            .carousel-indicators {
                li {
                    padding-top: calc(50px - 0.66rem);
                }
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-work {
        padding-top: 100px;
        .carousel-content {
            min-height: 535px;
            img {
                width: 600px;
            }
        }
        .work-content {
            position: absolute;
            width: 100%;
            height: 100%;

            padding: 28% 25% 28% 0;

            top: 0;
            left: -75%;
            right: 0;
            bottom: 0;
            opacity: 0;
            
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
        }
        .carousel-control {
            padding-right: 4%;
            .carousel-indicators {
                li {
                    width: 40px;
                }
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .component-work {
        .carousel-content {
            min-height: 638px;
            img {
                width: 720px;
            }
        }
        .carousel-control {
            .carousel-indicators {
                li {
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }
}