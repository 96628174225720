@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

@import '../../css/variables';

.cookiealert {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0 !important;
    z-index: 999;
    opacity: 0;
    border-radius: 0;
    transform: translateY(100%);
    transition: all 500ms ease-out;
    color: $main-color;
    background-color: rgba(255,255,255,0.95);
    backdrop-filter: blur(5px);
    font-size: 15px;

    &.show {
        opacity: 1;
        transform: translateY(0%);
        transition-delay: 1000ms;
    }

    a {
        text-decoration: underline
    }

    .acceptcookies {
        margin-left: 10px;
        vertical-align: baseline;
    }
}

#privacy-modal {
    .modal-header {
        border-bottom: none;
        background: $body-bg-color;

        button {
            &:focus, &:hover {
                background: transparent;
                color: #000;
            }
        }
    }
    .modal-body {
        max-height: calc(100vh - 210px);
        overflow-y: auto;
        background: $body-bg-color;
        -webkit-mask-image: linear-gradient(to bottom, $body-bg-color 77.5%, transparent 95%);
        mask-image: linear-gradient(to bottom, $body-bg-color 77.5%, transparent 95%);
        padding-bottom: 120px;
    }
    .modal-footer {
        border-top: none;
        background: $body-bg-color;
        
    }
    .table-bordered {
        th, td {
            border: 1px solid rgba(0, 0, 0, 0.12) !important;
            padding: 1rem;
        }
        th {
            font-family: 'Roboto-Regular';
        }
    }
}