@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

@import '../../css/variables';

@keyframes bounce {
    25% {
        transform: translateY(-3px);
    }
    75% {
        transform: translateY(3px);
    }
}

.component-intro {
    position: relative;
    z-index: 0;
    box-sizing: border-box;
    padding: 0;

    width: 100vw;
    height: 57.125vw;
    max-height: 90vh;
    .intro-animation-top {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        position: absolute;
        top: 28.8%;
        left: 50%;
        transform: translateX(-50%);

        width: calc(100% - 30px);
        max-width: 1140px - $grid-gutter-width;
        .intro-wings {
            width: inherit;
            max-width: inherit;

            position: relative;
            padding-bottom: 5%;

            img {
                width: 100%;
            }
        }
        .intro-company-name  {
            display: block;
            width: 75%;
        }
    }

    .container {
        height: inherit;
        max-height: inherit;
        position: relative;
    }

    .intro-animation-left,
    .intro-animation-right {
        position: absolute;
        bottom: -5%;
        left: 10vw;
        right: 10vw;
        font-size: 0.75rem;
        text-align: center;

        em {
            font-style: normal;
            // color: $link-dark-color;
            font-weight: 400;
            font-family: 'Roboto';
        }
    }
    .intro-animation-bottom {
        display: none;
        justify-content: center;
        align-items: center;

        height: 40px;
        width: 100%;

        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        background: none;
        outline: none;
        border: none;

        opacity: 1;
        transition: opacity .1s ease-in;
        img {
            display: block;
            width: 48px;
            height: 24px;

            animation-timing-function: linear;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-name: bounce;
        }
        &.is-hidden {
            opacity: 0;
        }
    }
}
@media only screen and (min-width: 360px) {
    .component-intro {
        .intro-animation-left,
        .intro-animation-right {
            bottom: 5%;
        }
    }
}
@media only screen and (min-width: 480px) {
    .component-intro {
        .intro-animation-left,
        .intro-animation-right {
            bottom: 11%;
        }
    }
}
@include media-breakpoint-up(sm) {
    .component-intro {
        .intro-animation-top {
            width: 74.125vw;
            .intro-company-name {
                width: 56%;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .component-intro {
        .intro-animation-left,
        .intro-animation-right {
            font-size: 1rem;
        }
        .intro-animation-bottom {
            display: flex;
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-intro {
        .intro-animation-right {
            left: auto;
            right: $grid-gutter-width * 0.5;
            text-align: right;
        }
        .intro-animation-left {
            left: $grid-gutter-width * 0.5;
            right: auto;
            font-size: 1.1rem;
            width: 50%;
            text-align: left;
        }
    }
}
@include media-breakpoint-up(xl) {
    .component-intro {
        .intro-animation-left,
        .intro-animation-right {
            bottom: 15%;
        }
        .intro-animation-left {
            font-size: 1.15rem;
        }
    }
}