.component-about {
  margin-top: 25px;
  padding-top: 15px;
}
.component-about #clients {
  min-height: 500px;
  padding: 19rem 0 5rem 0;
  background: url("./world-light.png") no-repeat top center;
}
.component-about #clients img {
  margin: 2rem 3rem;
}

@media (min-width: 768px) {
  .component-about {
    margin-top: 50px;
    padding-top: 30px;
  }
}
@media (min-width: 992px) {
  .component-about {
    margin-top: 100px;
    padding-top: 75px;
  }
}