$main-color: #1E1D21;
$white-color: #F5F5F5;
$link-color: #4353FF;
$link-dark-color: #2E39B5;
$success-color: #00C853;
$invalid-color: #FFA000;
$grey-color: rgba($main-color, 0.05);
$grey-dark-color: rgba($main-color, 0.2);
$body-bg-color: #DCDEDF;

$content-section-mt-mobile: 25px;
$content-section-pt-mobile: 15px;
$content-section-mt-tablet: 50px;
$content-section-pt-tablet: 30px;
$content-section-mt-desktop: 100px;
$content-section-pt-desktop: 75px;

$theme-colors: (
    'primary': $main-color,
    'secondary': $white-color,
    'warning': $invalid-color,
    'success': $success-color,
    'danger': $invalid-color
);
