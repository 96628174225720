@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.component-contact {
    padding-top: 3rem;
    padding-bottom: 5rem;
    background: #fff;
    min-height: 400px;

    h3 {
        font-size: 1.333em;
    }

    .col-left {
        h2 {
            flex: 1 0 auto;
            margin-bottom: 2rem;
        }
    }

    .col-right {
        h3 {
            margin-top: 0.5rem;
            margin-bottom: 1.5rem;
        }
    }

    #form-contact {
        .form-contact-error,
        .form-contact-success {
            display: none;
            min-height: 300px;
    
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
    
            img {
                width: 112px;
                height: 112px;
    
                margin-right: 80px;
            }
    
            div {
                h2 {
                    font-size: 1.9rem;
                    line-height: 1;
                    margin-bottom: 2rem;
                }
    
                p {
                    margin-bottom: 2rem;
                }
            }
        }
        .container-button {
            display: flex;
            flex-grow: 1;
            .btn {
                display: inline-flex;
                flex-grow: 1;
                justify-content: center;
            }
        }
    
        &.is-success {
            .form-contact-container {
                display: none;
            }
            .form-contact-success {
                display: flex;
            }
        }
        &.is-error {
            .form-contact-container {
                display: none;
            }
            .form-contact-error {
                display: flex;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .component-contact {
        padding-top: 80px;
        padding-bottom: 170px;
        h3 {
            font-size: 1.88889em;
        }
        .col-left {
            h2 {
                flex: 1 1 200px;
                margin-bottom: 0;
            }
        }
        #form-contact {
            .container-button {
                display: inline-block;
            }
        }
    }
}