@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

@import '../../css/variables';

.component-services {
    margin-top: $content-section-mt-mobile;
    padding-top: $content-section-pt-mobile;
    min-height: 500px;

    #service-cards {
        &>div {
            width: 260px;
            height: 350px;
            border: 1px solid #F5F5F5;
            border-radius: 8px;
            margin: 8px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            background-color: #fff;
            box-shadow: 0 0 10px 0 #fff;

            .icon {
                width: 90px;
                height: 90px;
                margin-top: 50px;
            }

            h3 {
                margin-top: 1em;
            }

            p {
                opacity: 1;
                padding: 1em;
                font-size: 0.75em;
                text-align: center;
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .component-services {
        margin-top: $content-section-mt-tablet;
        padding-top: $content-section-pt-tablet;
    }
}
@include media-breakpoint-up(lg) {
    .component-services {
        margin-top: $content-section-mt-desktop;
        padding-top: $content-section-pt-desktop;
        #service-cards {
            & > div {
                background-color: transparent;
                box-shadow: 0 0 0 0 transparent;
                transition: background-color 1s ease;
                transition: box-shadow 1s ease;
                .icon {
                    width: 112px;
                    height: 112px;
                    transition: width 1s ease, height 1s ease;
                }
    
                h3 {
                    margin-top: 1.75em;
                    transition: margin 1s ease;
                }
    
                p {
                    padding: 1em;
                    font-size: 0.75em;
                    opacity: 0;
                    transition: opacity 1s ease;
                    text-align: center;
                }
                &:hover {
                    background-color: #fff;
                    box-shadow: 0 0 10px 0 #fff;
    
                    .icon {
                        width: 90px;
                        height: 90px;
                    }
    
                    h3 {
                        margin-top: 1em;
                    }
    
                    p {
                        opacity: 1;
                    }
                }
            }
        }
    }
}