@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

@import '../../css/variables';

$footer-text-color: #9E9E9E;

.component-footer {
    background-color: $main-color;
    color: $white-color;
    text-align: center;
    .container {
        position: relative;
    }
    h3 {
        font-size: 1.16rem;
        font-weight: 400;
        line-height: 1;
    }
    .footer-brand {
        display: block;
        width: 190px;
        height: 54px;
        img {
            width: 100%;
        }
    }
    .footer-contacts {
        p {
            color: $footer-text-color;
            line-height: 2.2;
            margin-bottom: 0;
        }
    }
    .footer-navigation {
        .nav {
            flex-direction: column;
            .nav-item {
                .nav-link {
                    padding: 0;
                    font-size: 0.95rem;
                    color: $footer-text-color;
                    text-decoration: underline;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .footer-copy-container {
        padding: 1rem 0 5rem 0;
        small {
            color: $footer-text-color;
            font-size: 0.75rem;
            span {
                display: block;
            }
            a {
                color: $footer-text-color;
                text-decoration: underline;
                transition: color 0.5s ease;
                &:hover {
                    text-decoration: none;
                    color: rgb(255, 255, 255, 0.7);
                }
            }
        }
    }
    .footer-top {
        border: none;
        outline: none;
        background-color: rgba(245, 245, 245, 0.3);

        display: flex;
        justify-content: center;
        align-items: center;

        height: 48px;
        width: 48px;
        padding: 0;
        margin: 0;

        border-radius: 50%;
        position: absolute;
        bottom: 1rem;
        right: calc(50% - 24px);

        img {
            width: 36px;
            height: 18px;
            display: block;
        }
    }
}
@include media-breakpoint-up(sm) {
    .component-footer {
        .footer-navigation {
            .nav {
                flex-direction: row;
                justify-content: center;
                margin-top: 0.5rem;
                .nav-item {
                    padding-left: 1rem;
                    padding-right: 1rem; 
                }
                
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .component-footer {
        padding-top: 100px;
        padding-bottom: 100px;
        text-align: left;
        h3 {
            margin-bottom: 1.15em;
        }
        .footer-contacts {
            margin-bottom: 80px;
        }
        .footer-navigation {
            margin-bottom: 100px;
            .nav {
                justify-content: flex-start;
                .nav-item {
                    position: relative;
                    padding-right: 25px;
                    padding-left: 25px;
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                        &::after {
                            display: none;
                        }
                    }
                    &::after {
                        content: '';
                        display: block;
                        width: 1px;
                        height: 3px;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        background-color: $footer-text-color;
                    }
                }
            }
        }
        .footer-copy-container {
            padding: 0;
            small {
                span {
                    display: inline;
                }
            }
        }
        .footer-top {
            top: 0;
            right: 0;
        }
    }
}