@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

@import '../../css/variables';

.component-3d {
    width: 100vw;
    height: calc(57.125vw + 2px);
    position: relative;
    .animation-top {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        position: absolute;
        top: 63%;
        left: 50%;
        transform: translateX(-50%);

        width: calc(100% - 30px);
        max-width: 1140px - $grid-gutter-width;
        .company-name  {
            display: block;
            width: 75%;
        }
    }
    .container {
        height: inherit;
        max-height: inherit;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .animation-left,
    .animation-right {
        position: absolute;
        bottom: -5%;
        left: 10vw;
        right: 10vw;
        font-size: 0.65rem;
        text-align: center;
        margin-bottom: 0;
        em {
            font-style: normal;
            font-weight: 400;
            font-family: 'Roboto';
        }
    }
    .animation-bottom {
        display: none;
        justify-content: center;
        align-items: center;

        height: 40px;
        width: 100%;

        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        background: none;
        outline: none;
        border: none;
        img {
            display: block;
            width: 48px;
            height: 24px;

            animation-timing-function: linear;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-name: bounce;
        }
    }
}

@media only screen and (min-width: 360px) {
    .component-3d {
        .animation-left,
        .animation-right {
            bottom: 5%;
        }
    }
}
@media only screen and (min-width: 480px) {
    .component-3d {
        .animation-left,
        .animation-right {
            font-size: 0.75rem;
        }
    }
}
@include media-breakpoint-up(sm) {
    .component-3d {
        .animation-top {
            top: 55%;
            .company-name {
                width: 55%;
            }
        }
        .animation-left,
        .animation-right {
            bottom: 11%;
        }
    }
}

@include media-breakpoint-up(md) {
    .component-3d {
        .animation-left,
        .animation-right {
            font-size: 1rem;
        }
        .animation-bottom {
            display: flex;
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-3d {
        .animation-top {
            top: 50%;
            .company-name {
                width: 60%;
            }
        }
        .animation-right {
            left: auto;
            right: $grid-gutter-width * 0.5;
            text-align: right;
        }
        .animation-left {
            left: $grid-gutter-width * 0.5;
            right: auto;
            font-size: 1.1rem;
            width: 50%;
            text-align: left;
        }
    }
}
@include media-breakpoint-up(xl) {
    .component-3d {
        .animation-top {
            .company-name {
                width: 65%;
            }
        }
        .animation-left,
        .animation-right {
            bottom: 15%;
        }
        .animation-left {
            font-size: 1.15rem;
        }
    }
}