.component-header {
  background: transparent;
  box-shadow: none;
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  transition: box-shadow 0.3s ease-in;
}
.component-header .navbar {
  height: 58px;
  padding: 0;
}
.component-header .navbar-toggler {
  background: transparent;
  padding: 0;
  margin-right: 15px;
  height: 100%;
}
.component-header .navbar-toggler-icon {
  padding: 0;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.3s ease-in;
}
.component-header .navbar-toggler-icon .icon-close,
.component-header .navbar-toggler-icon .icon-open {
  width: 15px;
  height: 15px;
}
.component-header .navbar-toggler-icon .icon-open {
  display: block;
}
.component-header .navbar-toggler-icon .icon-close {
  display: none;
}
.component-header .navbar-toggler-icon:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.component-header .navbar-brand {
  width: 120px;
  padding-left: 15px;
  opacity: 0;
  transition: opacity 0.3s ease-in;
}
.component-header .navbar-nav .nav-item {
  margin-top: 20px;
  text-align: center;
}
.component-header .navbar-nav .nav-item .nav-link {
  position: relative;
  text-align: center;
  display: inline-block;
  padding: 10px;
  font-size: 1.25rem;
  color: #1E1D21;
}
.component-header .navbar-nav .nav-item .nav-link::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(./corner-top-left.svg), url(./corner-top-right.svg), url(./corner-bottom-right.svg), url(./corner-bottom-left.svg);
  background-repeat: no-repeat;
  background-size: 8px;
  background-position: 10% 10%, 90% 10%, 90% 90%, 10% 90%;
  opacity: 0;
  transition: opacity 0.4s ease-in, background-position 0.4s ease-in;
}
.component-header .navbar-nav .nav-item .nav-link:hover {
  color: #1E1D21;
}
.component-header .navbar-nav .nav-item .nav-link:hover::before {
  background-position: 0% 0%, 100% 0%, 100% 100%, 0% 100%;
  opacity: 1;
}
.component-header.shadowed {
  box-shadow: 6px 6px 8px rgba(79, 72, 100, 0.07);
  background-color: rgba(220, 222, 223, 0.5);
}
.component-header.shadowed .navbar-brand {
  opacity: 1;
}
.component-header.shadowed .navbar-toggler-icon {
  background-color: rgba(255, 255, 255, 0.3);
}
.component-header.shadowed .navbar-toggler-icon:hover {
  background-color: transparent;
}
.component-header.mobile-navigation-collapsed {
  box-shadow: none;
  background: #F5F5F5;
}
.component-header.mobile-navigation-collapsed .navbar-brand {
  opacity: 0 !important;
}
.component-header.mobile-navigation-collapsed .navbar-toggler-icon .icon-open {
  display: none;
}
.component-header.mobile-navigation-collapsed .navbar-toggler-icon .icon-close {
  display: block;
}
.component-header.mobile-navigation-collapsed .navbar-toggler-icon:hover {
  background-color: rgba(30, 29, 33, 0.05);
}
.component-header.mobile-navigation-collapsed .navbar-collapse {
  background: #F5F5F5;
}

@media (min-width: 992px) {
  .component-header .navbar {
    height: 68px;
  }
  .component-header .navbar-brand {
    width: 148px;
  }
  .component-header .navbar-collapse {
    height: inherit;
    background: transparent;
  }
  .component-header .navbar-toggler-icon {
    background-color: transparent;
  }
  .component-header .navbar-toggler-icon:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
  .component-header .navbar-nav {
    height: inherit;
  }
  .component-header .navbar-nav .nav-item {
    margin-top: 0;
    padding: 10px 25px;
    position: relative;
  }
  .component-header .navbar-nav .nav-item .nav-link {
    height: 100%;
    padding: 0 5px;
    font-size: 0.7778rem;
    line-height: 3.4;
    min-width: 58px;
  }
  .component-header .navbar-nav .nav-item .nav-link.prominent {
    color: #2E39B5;
  }
  .component-header .navbar-nav .nav-item .nav-link:hover {
    color: #2E39B5;
  }
  .component-header .navbar-nav .nav-item::after {
    content: "";
    display: block;
    width: 1px;
    height: 3px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #1E1D21;
  }
  .component-header .navbar-nav .nav-item:last-child::after {
    display: none;
  }
  .component-header .navbar-nav .navbar-toggler-icon {
    background-color: transparent;
  }
}